<template>
  <div class="sky_wrap">
    <div class="sky_btn">
      <div
        class="sky_item"
        v-for="(item, index) in skyList"
        :key="index"
        :class="activeIndex === index ? 'active' : ''"
        @click="changeIndex(index)"
      >
        {{ item.tit }}
      </div>
    </div>
    <div class="sky_content">
      <div class="content_left">
        <div class="left_tit">{{ skyTitle }}</div>
        <div class="left_icon_wrap">
          <div
            class="icon_item"
            v-for="(item, index) in skyImgList"
            :key="index"
            ref="iconItem"
          >
            <i :class="item.src"></i>
            <span v-html="item.txt"></span>
          </div>
        </div>
        <!-- <div class="left_btn hidden-xs-only" @click="handleToDeomo">获取产品演示</div> -->
      </div>
      <div class="content_right">
        <img :src="skycurrentImg" alt="" />
        <!-- <div class="left_btn hidden-sm-and-up" @click="handleToDeomo">获取产品演示</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      skyTitle:
    '为数据中心组织策划线上招商推介会、技术交流会等直播宣传推广活动，提供会前策划推广、目标客户定向邀请、会后持续宣传等全流程服务。',

skyImgList: [
    {
        src: '',
        txt: '<span style="font-weight:bold;padding-right:10px;">快速启动</span>轻松举办在线数据中心招商',
    },
    {
        src: '',
        txt: '<span style="font-weight:bold;padding-right:10px;">个性化二维码</span>提升每位参会者的VIP体验',
    },
    {
        src: '',
        txt: '<span style="font-weight:bold;padding-right:10px;">高效稳定</span>会议成果报告即时生成',
    },
],
      skycurrentImg:require('../../assets/img/33342e3d61.png')
    }
  },
  props: {
    skyList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    changeIndex(index) {
    this.activeIndex = index
    switch (this.activeIndex) {
        case 1:
            this.skyTitle =
                '基于数据中心高效资源整合，精准匹配企业需求，为客户提供定制化的数据中心投资解决方案。'
            this.skyImgList = [
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">智能化资源推荐</span>基于企业需求分析，提供定制的资源配置建议。',
                },
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">实时数据更新</span>持续获取数据中心市场动态与客户需求变化。',
                },
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">高效情报挖掘</span>挖掘市场数据，提供前沿的行业情报。',
                },
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">精准的客户对接</span>线上线下融合，助力快速推动项目落地。',
                },
            ]
            this.skycurrentImg = require('../../assets/img/222.a7a2174.png')
            break
        case 2:
            this.skyTitle =
                '开通数据中心的数字化营商环境功能，提供VR实景展示、云服务、技术平台的数字化，让客户便捷进行数据中心考察与选址。'
            this.skyImgList = [
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">VR实景展示</span>全面展现数据中心的硬件设施与服务内容。',
                },
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">3D建模效果</span>直观呈现数据中心的布局与规划。',
                },
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">数据中心配套设施</span>详细介绍数据中心相关配套服务与基础设施。',
                },
            ]
            this.skycurrentImg = require('../../assets/img/111.855fbdc (1).png')
            break
        default:
            this.skyTitle =
                '为数据中心组织策划线上招商推介会、网上洽谈会等直播宣传推介活动，提供会前策划推广、目标客户定向邀请、会后持续宣传等全流程服务。'
            this.$refs.iconItem.map(v => {
                v.style.width = '90%'
            })
            this.skyImgList = [
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">快速启动</span>轻松举办在线数据中心招商活动。',
                },
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">个性化二维码</span>为每位参会者提供VIP体验。',
                },
                {
                    src: '',
                    txt: '<span style="font-weight:bold;padding-right:10px;">高效稳定</span>即时生成会议成果报告。',
                },
            ]
            this.skycurrentImg = require('../../assets/img/33342e3d61.png')
            break
    }
},
    handleToDeomo(){
      this.$router.push('/demo')
    }
  },
}
</script>

<style lang="scss" scoped>
.sky_wrap {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.02) inset;
  @media screen and (max-width: 768px) {
    width: 95%;
    background: #f7f7f7;
  }
  .sky_btn {
    width: 100%;
    display: flex;
    .sky_item {
      flex: 1;
      height: 80px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      position: relative;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        height: 60px;
        text-align: center;
        font-size: 13px;
      }
    }
    .active {
      color: #fff;
      background: #2091d3;
      font-weight: bold;
      &::before {
        content: '▲';
        position: absolute;
        color: #2091d3;
        font-size: 40px;
        transform: rotateX(180deg);
        bottom: -27px;
      }
    }
  }
  .sky_content {
    width: 100%;
    display: flex;
    padding: 30px;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .content_left {
      width: 40%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .left_tit {
        color: #2d3937;
        font-size: 17px;
        min-height: 90px;
        line-height: 20PX;
        @media screen and (max-width: 768px) {
          font-size: 0.9rem;
          padding: 10px 20px;
          color: #646464;
        }
      }
      .left_icon_wrap {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        @media screen and (max-width: 768px) {
          justify-content: center;
        }
        .icon_item {
          width: 90%;
          height: 50px;
          @media screen and (max-width: 768px) {
            height: 50px;
          }
          i {
            color: #7bbde3;
            @media screen and (max-width: 768px) {
              font-size: 20px;
            } 
          }
          span {
            color: #646464;
             @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }
      .left_btn {
        width: 150PX;
        height: 40PX;
        background: orange;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
      }
    }
    .content_right {
      width: 40%;
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
      text-align: right;
      img {
        margin-top: 30px;
        width: 80%;
      }
      .left_btn {
        width: 180px;
        height: 50px;
        background: orange;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: 120px;
          height: 35px;
          font-size: 14px;
          border-radius: 20px;
          margin: 20px auto;
          cursor: pointer;
        }
      }
    }
  }
}
</style>