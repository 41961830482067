<template>
  <div class="enter_innovation_wrap">
    <div class="enter_img">
      <div class="img_tit hidden-xs-only">数据中心创新服务</div>
      <div class="img_desc hidden-xs-only">
        打造数据中心行业新基建，加速园区中小企业数字化转型
      </div>
      <div class="img_tit hidden-sm-and-up">
        打造数据中心行业新基建<br />加速园区中小企业数字化转型
      </div>
    </div>
    <div style="z-index: 9; position: relative; display: none">
      <Affix :offset-top="56">
        <Menu
          mode="horizontal"
          theme="light"
          :active-name="selectName"
          class="enter_menu_wrap"
          @on-select="selectMenuItem"
        >
          <MenuItem name="1" class="enter_menu_item">
            三支网 - 生物医药产业创新服务综合体
          </MenuItem>
          <MenuItem name="2" class="enter_menu_item">
            医疗器械创新示范基地
          </MenuItem>
        </Menu>
      </Affix>
    </div>
    <div class="current_show_menu">
      <div class="enter_title_wrap">
        <div class="enter_inner">
          <div class="inner_left">
            <img src="../../assets/img/tupianright.png" alt="" />
          </div>
          <div class="inner_center" style="width: 60%">
            <div class="enter_title">数据中心产业供应链至关重要</div>
            <div class="enter_desc hidden-xs-only">
              贯穿于数据中心建设、运营管理、维护服务及技术创新等整个生命周期
              利用新基建数字化杠杆，为园区强链补链是当下的必然选择
            </div>
          </div>
          <div class="inner_right">
            <img src="../../assets/img/tupianleft.png" alt="" />
          </div>
        </div>
      </div>
      <Row
        class-name="enter_card_wrap hidden-xs-only"
        :gutter="20"
        justify="center"
      >
        <Col span="6">
          <Card :bordered="true" class="enter_card_item">
            <p slot="title" class="title">园区管理方</p>
            <br />
            <br />
            <br />
            <p>
              如何提升数据中心基础设施的运营效率，为园区企业提供更多技术支持？
            </p>
            <br />
            <br />
            <br />
          </Card>
        </Col>
        <Col span="6">
          <Card :bordered="true" class="enter_card_item">
            <p slot="title" class="title">技术服务供应商</p>
            <br />
            <br />
            <br />
            <p>如何在快速发展的市场中，创新服务方法并扩大客户基础？</p>
            <br />
            <br />
            <br />
          </Card>
        </Col>
        <Col span="6">
          <Card :bordered="true" class="enter_card_item">
            <p slot="title" class="title">园区企业</p>
            <br />
            <br />
            <br />
            <p>如何获取高效能的数据处理服务，匹配最具竞争力的技术合作伙伴？</p>
            <br />
            <br />
            <br />
          </Card>
        </Col>
      </Row>
      <div class="enter_card_wrap_mobile hidden-sm-and-up">
        <img src="@/assets/img/16f7580f76.png" alt="" />
      </div>
      <div class="enter_title_wrap">
        <div class="enter_inner">
          <div class="inner_left">
            <img src="../../assets/img/tupianright.png" alt="" />
          </div>
          <div class="inner_center">
            <div class="enter_title">走进 三支网</div>
            <div class="enter_desc"></div>
          </div>
          <div class="inner_right">
            <img src="../../assets/img/tupianleft.png" alt="" />
          </div>
        </div>
      </div>
      <div class="mall_wrap">
        <div class="mall_img">
          <img src="@/assets/img/bioBg9526da1.png" alt="" />
        </div>
        <div class="mall_details">
          <p>
            数据中心是依托园区、服务周边地区，推动中小企业数字化转型和提升园区服务能力的重要基础设施。通过线上和线下相结合的模式，汇聚全球优质的IT资源，不仅为企业提供高效、安全、可扩展的计算和存储服务，还无缝对接上下游产业链，助力企业在数字化研发、数据管理和创新应用方面取得成本优势与技术领先。
          </p>
          <div class="mall_btn">
            <!-- <el-button class="btn_self" @click="gotoDemo">了解更多</el-button> -->
            <el-button class="btn_self">走进三支网</el-button>
          </div>
        </div>
      </div>
      <div class="func_wrap">
        <div class="func_wrap_inner">
          <div class="func_tit">三支网 功能体系</div>
          <el-row class="func_card hidden-xs-only" type="flex">
            <el-col
              class="card_item"
              v-for="(item, index) in funcCardList"
              :key="index"
              :span="5"
            >
              <div class="item_top" :style="{ background: `url(${item.bg})` }">
                <p>{{ item.tit }}</p>
              </div>
              <div class="item_bottom">
                <p v-for="(item2, index2) in item.desc" :key="index2">
                  {{ "○  " + item2 }}
                </p>
              </div>
            </el-col>
          </el-row>
          <div class="func_card_mobile hidden-sm-and-up">
            <img src="@/assets/img/3572170410.png" alt="" />
          </div>
          <div class="func_card_mobile hidden-sm-and-up">
            <img src="@/assets/img/3572170410.png" alt="" />
          </div>
          <div class="func_card_mobile hidden-sm-and-up">
            <img src="@/assets/img/3572170410.png" alt="" />
          </div>
        </div>
      </div>
      <div class="func_wrap" style="background: #f4f9fc">
        <div class="func_wrap_inner">
          <div class="func_tit">三支网 四大核心价值</div>
        </div>
        <div class="double_card_wrap">
    <el-row class="double_card" type="flex">
        <el-col :span="5" :xs="11" class="double_card_item">
            <div class="double_card_bg"></div>
            <p>不仅是云计算的数字化基础设施，更是线上与线下资源整合的全方位服务平台</p>
        </el-col>
        <el-col :span="5" :xs="11" class="double_card_item">
            <div class="double_card_bg"></div>
            <p>优化数据中心资源配置，助力区域计算和存储能力的快速提升</p>
        </el-col>
        <el-col :span="5" :xs="11" class="double_card_item">
            <div class="double_card_bg"></div>
            <p>解决企业在云服务过程中面临的关键技术和资源瓶颈问题</p>
        </el-col>
        <el-col :span="5" :xs="11" class="double_card_item">
            <div class="double_card_bg"></div>
            <p>吸引优质IT服务提供商入驻，促进产业链升级，提升区域经济效益</p>
        </el-col>
    </el-row>
</div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from "@/components/callme/index";
import MobileCallme from "@/components/callme/MobileCallme.vue";
export default {
  data() {
    return {
      selectName: "1",
      funcCardList: [
    {
        tit: "云计算服务平台",
        desc: [
            "提供数据存储、计算资源和网络服务的综合解决方案",
            "支持企业基础设施的在线管理和监控",
            "政企数据合作与共享",
            "基于AI/大数据的业务智能分析服务",
        ],
        bg: require("../../assets/img/bg1.png"),
    },
    {
        tit: "数据中心综合服务集成",
        desc: [
            "优质数据中心服务提供商及其产品与解决方案展示",
            "专业运营团队为企业提供定制化的咨询和技术支持",
        ],
        bg: require("../../assets/img/bg2.png"),
    },
    {
        tit: "智能仓储与调度中心",
        desc: [
            "云端仓储管理系统，实现供应链的智能调度",
            "支持园区企业的共享和协同仓储",
        ],
        bg: require("../../assets/img/bg3.png"),
    },
    {
        tit: "跨境数据传输与处理中心",
        desc: [
            "提供跨境数据传输服务和相关合规支持",
            "为国内外客户提供全方位的数据处理解决方案",
        ],
        bg: require("../../assets/img/bg3.png"),
    },
],
    };
  },
  methods: {
    selectMenuItem(name) {
      console.log(name);
    },
    gotoDemo() {
      this.$router.push("/demo");
    },
  },
  components: {
    CallMe,
    MobileCallme,
  },
};
</script>

<style lang="scss" scoped>
.enter_innovation_wrap {
  width: 100%;
  .enter_img {
    width: 100%;
    height: 600px;
    @media screen and (max-width: 768px) {
      height: 50vw;
    }
    background: url("../../assets/img/banner22.png") 0 0 no-repeat;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .img_tit {
      font-size: 60px;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        text-align: center;
      }
    }
    .img_desc {
      font-size: 32px;
      margin-top: 20px;
      text-align: center;
      @media screen and (max-width: 768px) {
        font-size: 0.85rem;
      }
    }
    .img_btn {
      width: 180px;
      height: 50px;
      border: solid 1px rgb(255, 166, 0);
      background: rgba(25, 166, 0, 0.1);
      color: orange;
      position: absolute;
      bottom: 15%;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        color: #fff;
        background: orange;
      }
      @media screen and (max-width: 768px) {
        width: 80px;
        height: 30px;
        font-size: 14px;
      }
    }
  }
  .enter_menu_wrap {
    @media screen and (max-width: 768px) {
      height: 40px;
    }
    .enter_menu_item {
      width: 50%;
      text-align: center;
      font-size: 22px;
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .enter_title_wrap {
    width: 100%;
    margin-top: 50px;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }
    .enter_inner {
      display: flex;
      justify-content: center;
      .inner_left {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: 768px) {
          flex: 1;
        }
        img {
          width: 30%;
        }
      }
      .inner_center {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        @media screen and (max-width: 768px) {
          flex: 2.2;
        }
        .enter_title {
          font-size: 40px;
          font-weight: bold;
          @media screen and (max-width: 768px) {
            font-size: 1rem;
          }
        }
        .enter_desc {
          font-size: 16px;
          color: #787878;
          margin-top: 10px;
          @media screen and (max-width: 768px) {
            margin-top: 10px;
          }
        }
      }
      .inner_right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media screen and (max-width: 768px) {
          flex: 1;
        }
        img {
          width: 30%;
        }
      }
    }
  }
  .current_show_menu {
    .enter_card_wrap_mobile {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .enter_card_wrap {
      width: 100%;
      margin-top: 50px;
      .enter_card_item {
        background: #f4f9fc;
        text-align: center;
        p {
          width: 60%;
          text-align: center;
          margin: 0 auto;
          font-size: 16px;
        }
        .title {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .mall_wrap {
      width: 70%;
      display: flex;
      justify-content: center;
      margin: 34px auto;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .mall_img {
        width: 50%;
        margin-right: 30px;
        padding: 0 30px;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
    .mall_details {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 768px) {
        width: 90%;
        text-align: center;
      }
      p {
        font-size: 20px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      .mall_btn {
        margin-top: 40px;
        @media screen and (max-width: 768px) {
          padding: 20px 0;
        }
        .btn_self {
          background: orange;
          color: #fff;
          border-radius: 20px;
          border: none;
          @media screen and (max-width: 768px) {
            width: 80px;
            height: 35px;
            border-radius: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .func_wrap {
    width: 100%;
    padding: 50px;
    background: url("../../assets/img/bg_0.png");
    background-size: 100% 100%;
    .func_wrap_inner {
      width: 70%;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      .func_tit {
        font-size: 34px;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
      .func_card {
        justify-content: space-between;
        margin-top: 40px;
        .card_item {
          background: #fff;
          box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
          .item_top {
            width: 100%;
            height: 150px;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .item_bottom {
            padding: 15px;
            p {
              font-size: 16px;
              margin-top: 20px;
            }
          }
        }
      }
      .func_card_mobile {
        width: 100%;
        img {
          width: 100%;
          margin-top: 40px;
        }
      }
    }
    .double_card_wrap {
      width: 70%;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      .double_card {
        justify-content: space-between;
        margin-top: 50px;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
        .double_card_item {
          height: 250px;
          background: rgba($color: #fff, $alpha: 1);
          padding: 30px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          &:nth-child(1) {
            background-image: url("../../assets/img/bg_1.png");
          }
          &:nth-child(2) {
            background-image: url("../../assets/img/bg_2.png");
          }
          &:nth-child(3) {
            background-image: url("../../assets/img/bg_3.png");
          }
          &:nth-child(4) {
            background-image: url("../../assets/img/bg_4.png");
          }
          background-position: 100% 100%;
          background-repeat: no-repeat;
          background-size: 30%;
          @media screen and (max-width: 768px) {
            height: 140px;
            &:nth-child(n + 3) {
              margin-top: 20px;
            }
          }
          .double_card_bg {
            width: 30px;
            height: 15px;
            background: linear-gradient(90deg, rgba(252, 197, 96, 0), #feb15a);
            @media screen and (max-width: 768px) {
              width: 20px;
              height: 8px;
            }
          }
          p {
            line-height: 20px;
            font-size: 15px;
            margin-top: 30px;
            @media screen and (max-width: 768px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
</style>
