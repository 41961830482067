<template>
  <div class="data_brain_wrap">
    <div class="brain_title">三支网网络</div>
    <div class="brain_desc">数智赋能、高效协同，助力实现产业高质量发展</div>

    <el-row class="card_wrap" type="flex">
      <el-col
        class="brain_item"
        :lg="7"
        :sm="11"
        :xs="11"
        v-for="(item, index) in cardList"
        :key="index"
      >
        <div class="item_title">{{ item.title }}</div>
        <div class="item_desc">{{ item.desc }}</div>
        <div class="item_navigate" @click="handleNavigate">了解详情</div>
        <div class="item_bg"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardList: [
  {
    title: '数据中心基础设施',
    desc: '构建高可用、高性能的数据中心基础设施，提供稳定的电力、制冷和网络服务，保障客户数据安全和业务连续性。',
  },
  {
    title: '云计算服务',
    desc: '通过弹性云计算资源，满足企业在计算、存储及网络方面的动态需求，支持快速部署和高效管理。',
  },
  {
    title: '网络安全',
    desc: '实施全面的网络安全防护措施，提供DDoS防护、入侵检测、数据加密等解决方案，确保客户数据的隐私与安全。',
  },
  {
    title: '数据备份与恢复',
    desc: '提供专业的数据备份与恢复服务，确保在系统故障时能快速恢复业务，最大限度降低业务中断的风险。',
  },
  {
    title: '灾备解决方案',
    desc: '为企业定制灾难恢复计划，确保在顽固的自然灾害或系统故障情况下，实现业务的持续性和稳定性。',
  },
  {
    title: '客户支持与服务',
    desc: '提供24/7专业技术支持，帮助客户快速解决故障与疑问，确保客户获得优质的服务体验。',
  },
],
    }
  },
  methods:{
    handleNavigate(){
      this.$router.push('/production/1').catch((v) => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.data_brain_wrap {
  padding: 50px 0;
  background: #e8eef2;
  text-align: center;
  .brain_title {
    color: #203040;
    font-size: 40px;
    font-weight: bold;
    padding: 30px 0;
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  .brain_desc {
    color: rgba(32, 48, 64, 0.75);
    font-size: 22px;
    letter-spacing: 4px;
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
      line-height: 40PX;
    }
  }
  .card_wrap {
    width: 70%;
    flex-wrap: wrap;
    margin: 0px auto;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
      width: 95%;
    }
    .brain_item {
      transition: background-color 1s;
      padding: 30px;
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        line-height: 40PX;
        border-radius: 10PX;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20PX;
        &:nth-child(-n+2){
          margin-top: 0;
        }
      }
      background: #fff;
      border-radius: 20px;
      box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 135, 168, 0.15);
      margin-top: 40px;
      position: relative;
      .item_bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          134deg, 
          #0649b8 1%,
          #0a7fd7 41%,
          #03d2c5 99%
        );
        opacity: 0;
        transition: all 0.5s;
        border-radius: 20px;
        z-index: 1;
      }
      &:hover .item_bg{
        opacity: 1;
      }
      &:hover .item_title {
        color: #fff;
      }
      &:hover .item_desc {
        color: #fff;
      }
      .item_title {
        transition: all 0.5s;
        font-size: 30px;
        font-weight: bold;
        color: #203040;
        text-align: left;
        padding: 20px 0 40px 0;
        z-index: 2;
        position: inherit;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
          text-align: center;
        }
      }
      .item_desc {
        transition: all 0.5s;
        color: #666;
        text-align: left;
        line-height: 1.5rem;
        z-index: 2;
        position: inherit;
        font-size: 18px;
        min-height: 110px;
        @media screen and (max-width: 768px) {
          min-height: 110PX;
          text-indent: 2em;
          font-size: 12px;
        }
      }
      .item_navigate {
        width: 100PX;
        height: 30PX;
        padding: 0 10px;
        background: #ffa400;
        color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-top: 40px;
        cursor: pointer;
        z-index: 2;
        position: inherit;
        @media screen and (max-width: 768px) {
          margin-bottom: 10PX;
          font-size: 14px;
        }
      }
    }
  }
}
</style>