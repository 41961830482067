<template>
  <div class="research_wrap">
    <el-carousel
      height="37vw"
      loop
      arrow="never"
      class="hidden-xs-only"
      indicator-position="none"
    >
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img :src="item.src" alt="" class="img_item" />
        <div class="tips_font">
          <h1>{{ item.line1 }}</h1>
          <h1>{{ item.line2 }}</h1>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="production_img hidden-sm-and-up">
      <div class="img_tit">三支网研究院</div>
      <div class="img_desc">
        专注于数据中心与云计算技术研究，构建智能化的数据服务生态系统
      </div>
    </div>

    <div class="research_card hidden-xs-only">
      <div class="research-left">
        <div class="research-left-title">三支网研究院</div>
        <div class="research-left-desc">
          数据科技研究院起源于对数据中心和云计算产业的研究，致力于利用数字技术和人工智能，构建智能化的数据服务系统，推动研究模式的变革，支撑更精准、更高效的决策支持服务。
        </div>
        <div class="research-left-instrc">核心能力</div>
        <ul>
          <li class="research-left-detail">
            <span>数据分析数字化能力：</span>
            以实时数据分析为核心，改变传统数据处理模式，将多源数据集成于统一的分析平台，从用户需求、市场趋势、技术创新等多维角度，形成对数据中心运营的综合评估体系。
          </li>
          <li class="research-left-detail">
            <span>云架构数字化构建能力：</span>
            将云计算架构设计与动态资源管理相结合，形成高可用、高扩展的智能化云服务体系，支撑从基础设施管理到应用部署的高效运营。
          </li>
          <li class="research-left-detail">
            <span>数据中心动态研究能力：</span>
            凝聚数据科技在数据治理和智能分析领域的优势，基于数据分析评价体系和云架构构件的研究，支撑数据中心资源优化、性能监测和安全评估的“智能数据管理中心”建设。
          </li>
        </ul>
      </div>
      <div class="research_card_right">
        <img src="@/assets/img/build.543dd76.png" alt="" />
      </div>
    </div>

    <div class="research_card_mobile hidden-sm-and-up">
    <h1>数据科技研究院</h1>
    <p>
        数据科技研究院起源于对数据中心和云计算产业的研究，致力于利用数字科技和人工智能，构建智能化的数据服务系统，推动研究模式的创新，支撑更精准、更高效的决策支持。
    </p>
    <h2>团队介绍</h2>
    <p>
        数据科技研究院团队由30多人组成，核心成员包括数据科学家、行业专家和技术分析师，总部设在山东。研究院形成了独特的“数据分析+云服务”的双轨研究模式，构建数据行业新标杆，为客户提供深度见解的数字化服务和区域特有的解决方案。
    </p>
    <img src="@/assets/img/build.543dd76.png" alt="数据科技研究院" />
</div>

    <div class="research_title_wrap">
      <div class="research_inner">
        <div class="inner_left">
          <img src="../../assets/img/tupianright.png" alt="" />
        </div>
        <div class="inner_center">
          <div class="research_title">三大特色</div>
          <div class="research_desc"></div>
        </div>
        <div class="inner_right">
          <img src="../../assets/img/tupianleft.png" alt="" />
        </div>
      </div>
    </div>

    <div class="research_sp_wrap hidden-xs-only">
      <el-row
        type="flex"
        justify="space-between"
        class="research_sp_row"
        :gutter="10"
      >
        <el-col :span="7" class="research_sp_col">
          <div class="tit">面向政府</div>
          <div class="desc">
            面向政府的重大战略研究，支撑服务于国家发展改革委、科技部等的决策研究
          </div>
        </el-col>
        <el-col :span="7" class="research_sp_col">
          <div class="tit">面向数字经济</div>
          <div class="desc">
            面向数字经济时代的产业新基建研究，探索构建以“产业大脑”为核心的产业数字化管理新模式
          </div>
        </el-col>
        <el-col :span="7" class="research_sp_col">
          <div class="tit">面向行业</div>
          <div class="desc">
            面向行业的指数研究产品，着力构建新时代产业研究与评价的新标杆，为政府战略决策与产业研究提供工具和产品
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="research_sp_wrap_mobile hidden-sm-and-up">
      <img src="@/assets/img/content1.png" alt="" />
    </div>

    <div class="product_shows_wrap">
      <div class="production_title_wrap">
        <div class="production_inner">
          <div class="inner_left">
            <img src="../../assets/img/tupianright.png" alt="" />
          </div>
          <div class="inner_center" style="width: 40%">
            <div class="production_title">研究院研究方向与成果</div>
          </div>
          <div class="inner_right">
            <img src="../../assets/img/tupianleft.png" alt="" />
          </div>
        </div>
      </div>
      <div class="card_fan_list">
        <el-row class="card_fan_wrap" type="flex" justify="space-between">
          <el-col
            class="card_fan_item"
            :span="11"
            v-for="(item, index) in cardList"
            :key="index"
          >
            <div class="card_left hidden-xs-only">
              <img :src="item.img" alt="" />
            </div>
            <div class="card_right">
              <h1>{{ item.tit }}</h1>
              <p>{{ item.desc }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="research_title_wrap">
      <div class="research_inner">
        <div class="inner_left">
          <img src="../../assets/img/tupianright.png" alt="" />
        </div>
        <div class="inner_center" style="width: 70%">
          <div class="research_title">IDC产业发展</div>
          <div class="research_desc"></div>
        </div>
        <div class="inner_right">
          <img src="../../assets/img/tupianleft.png" alt="" />
        </div>
      </div>
    </div>

    <div class="develop_wrap">
      <div class="develop_left">
    <h1>BT与IT融合，数据经济时代的“趋势线”</h1>
    <p>
        中国数据中心行业发展指数（China Data Center Industry Index，简称DCI）作为首个国家级的数据中心行业指数，基于城市、园区、企业、资本四类成分机构，从经济、创新、要素等角度进行综合、量化、实时监测，生成反映我国数据中心行业发展的“趋势线”。用数字标杆打造数据中心行业高质量发展“风向标”，标志着我国数据中心行业区域发展评价迈入“数字对标”时代，对行业进行动态、全方位、科学评价。
    </p>
</div>
      <div class="develop_right">
        <img src="@/assets/img/cbib.3166b1c.png" alt="" />
        <!-- <a class="tips hidden-xs-only" href="http://www.cbib.org.cn/#/kpi/trendLine" target="_blank">官网入口>></a> -->
      </div>
    </div>
    <CallMe />
    <BoottomTab />
  </div>
</template>

<script>
import CallMe from "@/components/callme/index";
import MobileCallme from "@/components/callme/MobileCallme.vue";
import BoottomTab from "@/components/consulting/BoottomTab.vue";
export default {
  data() {
    return {
      imgList: [
        {
          src: require("@/assets/img/yjbg1.550eeb6.png"),
          line1: "三支网研究院",
          line2: "专注于数据中心与云计算技术研究，构建智能化的数据服务生态系统",
        },
      ],
      cardList: [
    {
        tit: "面向政府重大战略研究",
        desc: "数据中心发展战略规划",
        img: require("../../assets/img/researchInstitute/icon1.png"),
    },
    {
        tit: "面向新基建产业数字化研究",
        desc: "数字基础设施建设战略白皮书",
        img: require("../../assets/img/researchInstitute/icon2.png"),
    },
    {
        tit: "面向行业数据指数研究产品",
        desc: "中国数据中心行业发展指数DCI",
        img: require("../../assets/img/researchInstitute/icon3.png"),
    },
    {
        tit: "面向智库数据研究数字化产品",
        desc: "政策/资金数据研究数字化工具数鉴.数据中心(2024)",
        img: require("../../assets/img/researchInstitute/icon4.png"),
    },
],
    };
  },
  components: {
    CallMe,
    MobileCallme,
    BoottomTab,
  },
};
</script>

<style lang="scss" scoped>
.research_wrap {
  position: relative;
  width: 100%;
  .production_img {
    width: 100%;
    height: 600px;
    @media screen and (max-width: 768px) {
      height: 50vw;
    }
    background: url("../../assets/img/banner22.png") 0 0 no-repeat;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .img_tit {
      font-size: 60px;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .img_desc {
      font-size: 32px;
      margin-top: 20px;
      @media screen and (max-width: 768px) {
        font-size: 0.85rem;
      }
    }
    .img_btn {
      width: 180px;
      height: 50px;
      border: solid 1px rgb(255, 166, 0);
      background: rgba(25, 166, 0, 0.1);
      color: orange;
      position: absolute;
      bottom: 15%;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        color: #fff;
        background: orange;
      }
      @media screen and (max-width: 768px) {
        width: 80px;
        height: 30px;
        font-size: 14px;
      }
    }
  }
  .el-carousel__item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .tips_font {
    position: absolute;
    top: 260px;
    left: 300px;
    z-index: 5;
    color: #2d3937;
    font-size: 25px;
    @media screen and (max-width: 768px) {
      font-size: 0.5rem;
    }
  }
  .research_card {
    width: 72%;
    margin: 0 auto;
    position: relative;
    z-index: 5;
    margin-top: -200px;
    background: #ffffff;
    padding: 40px;
    display: flex;
    background: url("../../assets/img/bg.722080d.png");
    background-size: 100% 100%;
    .research-left {
      .research-left-title {
        font-size: 34px;
        font-weight: bold;
        color: #000;
      }
      .research-left-desc {
        color: #5b6e74;
        line-height: 2rem;
        font-size: 19px;
      }
      .research-left-instrc {
        font-size: 24px;
        margin-top: 20px;
      }
      ul {
        .research-left-detail {
          font-size: 18px;
          margin-top: 12px;
          span {
            font-weight: bold;
            color: #000;
          }
        }
      }
    }
    .research_card_right {
      margin-left: 50px;
      img {
      }
    }
  }
  .research_card_mobile {
    width: 100%;
    padding: 1rem;
    background: url("../../assets/img/bg.722080d.png") 0 0 no-repeat;
    background-size: cover;
    position: relative;
    h1 {
      font-size: 1.3rem;
    }
    p {
      margin-top: 1rem;
      font-size: 0.9rem;
      color: #5b6e74;
      line-height: 1.5rem;
    }
    h2 {
      margin-top: 1rem;
      font-weight: normal;
      font-size: 1rem;
      color: #000;
    }
    img {
      position: absolute;
      right: 1rem;
      width: 30%;
      bottom: -4rem;
      opacity: 0.6;
    }
  }
  .research_title_wrap {
    width: 100%;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }
    .research_inner {
      display: flex;
      justify-content: center;
      .inner_left {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: 768px) {
          flex: 1;
        }
        img {
          width: 30%;
        }
      }
      .inner_center {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        @media screen and (max-width: 768px) {
          flex: 2.2;
        }
        .research_title {
          font-size: 40px;
          font-weight: bold;
          @media screen and (max-width: 768px) {
            font-size: 1rem;
          }
        }
        .research_desc {
          font-size: 16px;
          color: #787878;
          margin-top: 10px;
          @media screen and (max-width: 768px) {
            margin-top: 10px;
          }
        }
      }
      .inner_right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media screen and (max-width: 768px) {
          flex: 1;
        }
        img {
          width: 30%;
        }
      }
    }
  }
  .research_sp_wrap {
    width: 70%;
    margin: 0 auto;
    height: 300px;
    .research_sp_row {
      width: 100%;
      .research_sp_col {
        background-size: 100% 100%;
        height: 420px;
        padding: 200px;
        &:nth-child(1) {
          background: url("../../assets/img/researchInstitute/1.png");
        }
        &:nth-child(2) {
          background: url("../../assets/img/researchInstitute/2.png");
        }
        &:nth-child(3) {
          background: url("../../assets/img/researchInstitute/3.png");
        }
        .tit {
          font-size: 32px;
          color: #000;
          margin: 0 30px;
        }
        .desc {
          font-size: 19px;
          margin: 10px 30px;
        }
      }
    }
  }
  .research_sp_wrap_mobile {
    width: 90%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .product_shows_wrap {
    width: 100%;
    background: #f4f9fc;
    background-size: cover;
    padding: 10px 0 50px 0;
    box-sizing: border-box;
    margin-top: 250px;
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }
    .production_title_wrap {
      width: 100%;
      margin-top: 50px;
      @media screen and (max-width: 768px) {
        margin-top: 30px;
      }
      .production_inner {
        display: flex;
        justify-content: center;
        .inner_left {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          @media screen and (max-width: 768px) {
            flex: 1;
          }
          img {
            width: 30%;
          }
        }
        .inner_center {
          width: 400px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 50px;
          @media screen and (max-width: 768px) {
            flex: 2.2;
          }
          .production_title {
            font-size: 40px;
            font-weight: bold;
            @media screen and (max-width: 768px) {
              font-size: 1rem;
            }
          }
          .production_desc {
            font-size: 16px;
            color: #787878;
            margin-top: 10px;
            @media screen and (max-width: 768px) {
              margin-top: 10px;
            }
          }
        }
        .inner_right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @media screen and (max-width: 768px) {
            flex: 1;
          }
          img {
            width: 30%;
          }
        }
      }
    }
    .card_fan_list {
      width: 70%;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      .card_fan_wrap {
        flex-wrap: wrap;
        margin-top: 50px;
      }
      .card_fan_item {
        display: flex;
        align-items: center;
        background: #fff;
        margin-top: 50px;
        padding: 30px;
        box-shadow: 10px 10px 20px rgba($color: #000, $alpha: 0.1);
        @media screen and (max-width: 768px) {
          background: url("../../assets/img/icon2.png") 0 0 no-repeat;
          background-position: 100% 100%;
          background-size: 35%;
          height: 100px;
          &:nth-child(n + 3) {
            margin-top: 25px;
          }
        }
        .card_left {
          width: 20%;
          img {
            width: 100%;
          }
        }
        .card_right {
          margin-left: 15px;
          h1 {
            font-size: 25px;
            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
          p {
            font-size: 18px;
            margin-top: 10px;
            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .develop_wrap {
    width: 70%;
    margin: 0 auto;
    display: flex;
    @media screen and (max-width: 768px) {
      width: 90%;
      flex-direction: column;
      margin-bottom: 30px;
    }
    .develop_left {
      margin-right: 100px;
      h1 {
        font-size: 26px;
        @media screen and (max-width: 768px) {
          font-size: 0.95rem;
        }
      }
      p {
        margin-top: 30px;
        font-size: 20px;
        color: #5b6e74;
        @media screen and (max-width: 768px) {
          font-size: 13px;
        }
      }
    }
    .develop_right {
      width: 100%;
      @media screen and (max-width: 768px) {
        width: 40%;
        margin: 0 auto;
      }
      img {
        width: 100%;
      }
      .tips {
        text-align: center;
        color: #2091d3;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
