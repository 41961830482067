var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consult_wrap"},[_c('div',{staticClass:"consult_btn"},_vm._l((_vm.consultList),function(item,index){return _c('div',{key:index,staticClass:"consult_item",class:_vm.activeIndex === index ? 'active' : '',on:{"click":function($event){return _vm.changeIndex(index)}}},[_vm._v(" "+_vm._s(item.tit)+" ")])}),0),_c('div',{staticClass:"consult_content"},[_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v(" 企业级服务器 ")]),_vm._m(0),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data1,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("超频I9区")]),_vm._m(1),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data2,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("江苏扬州BGP（省清洗/超频）")]),_vm._m(2),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data3,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("扬州电信（高防）")]),_vm._m(3),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data4,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("杭州BGP（超频）")]),_vm._m(4),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data5,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("台州BGP（/高防超频）")]),_vm._m(5),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data6,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("绍兴BGP（超频）")]),_vm._m(6),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data7,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("镇江BGP")]),_vm._m(7),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data8,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1),_c('br'),_c('br'),_c('h1',{staticStyle:{"text-align":"center","font-size":"32px"}},[_vm._v("宿迁BGP")]),_vm._m(8),_c('el-table',{staticClass:"animate-table",staticStyle:{"width":"100%","font-size":"13px"},attrs:{"data":_vm.actTag0Data9,"border":"","header-cell-style":{
        color: '#fff',
        backgroundColor: 'rgb(32, 145, 211)',
        padding: '18px 0',
        textAlign: 'center',
        fontSize: '14px',
      },"cell-style":{
        'text-align': 'center',
        'line-height': '40px' /* 或者使用 height */,
        height: '40px' /* 设置单元格的高度 */,
      }}},[_c('el-table-column',{attrs:{"prop":"t1","label":"配置","width":"280"}}),_c('el-table-column',{attrs:{"prop":"t2","label":"IP 地址"}}),_c('el-table-column',{attrs:{"prop":"t3","label":"带宽"}}),_c('el-table-column',{attrs:{"label":"DDOS 防护"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.t4))]),_c('span',{staticStyle:{"text-decoration":"underline dashed","cursor":"pointer","color":"#409eff"},on:{"click":function($event){_vm.dialogTableVisible = true}}})]}}])}),_c('el-table-column',{attrs:{"prop":"t5","label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"boll"},[_vm._v(_vm._s(scope.row.t5))]),_vm._v(" / 月 ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":""}},[_vm._v("立即购买")]),_c('el-button',{attrs:{"size":""}},[_vm._v("咨询销售")])]}}])})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-name flex-css flex-a-items mb40"},[_c('h1',[_vm._v("三支网")])])
}]

export { render, staticRenderFns }