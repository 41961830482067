<template>
  <el-row class="advantage_wrap">
    <el-col
      class="advantage_item"
      v-for="(item, index) in advantageList"
      :key="index"
      :span="5"
      :xs="11"
    >
      <div class="item_top">
        <div class="icon"><img :src="item.icon" alt=""></div>
        <div class="tit">{{ item.title }}</div>
      </div>
      <ul class="item_desc hidden-xs-only" v-for="(desc, index2) in item.desc" :key="index2">
        <li>{{ desc }}</li>
      </ul>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      advantageList: [
    {
        icon: require('../../assets/img/programme/industryInsight/icon/41.png'),
        title: '完善的技术架构经过多次项目验证',
        desc: [
            '我们的IDC解决方案在全国多个城市广泛应用，服务于金融、医疗等多个高需求行业',
            '已形成数据中心管理、智能监控、资源优化等多个成熟应用场景',
        ],
    },
    {
        icon: require('../../assets/img/programme/industryInsight/icon/42.png'),
        title: '灵活的架构支持快速部署与扩展',
        desc: [
            '通过实际项目总结的行业最佳实践，形成了可重用的PaaS层解决方案',
            '支持快速构建和扩展多种数据中心管理和监控应用场景',
        ],
    },
    {
        icon: require('../../assets/img/programme/industryInsight/icon/43.png'),
        title: '指标与模型构建增强数据中心洞察力',
        desc: [
            '微观层面形成数字化设备与用户画像',
            '宏观层面绘制数据中心全景，进行性能评价与容量规划',
            '构建故障预测、性能监测、能效分析等大数据模型',
        ],
    },
    {
        icon: require('../../assets/img/programme/industryInsight/icon/44.png'),
        title: '建设公共数据中心形成丰富的产品和服务应用',
        desc: [
            '使用智能化数据管理支撑数据中心运营和场景构建',
            '自主研发智能数据处理平台，实现数据采集、分析与决策的自动化',
        ],
    },
],
    }
  },
}
</script>

<style lang="scss" scoped>
.advantage_wrap {
  width: 70%;
  display: flex;
  margin-top: 70px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
  .advantage_item {
    height: auto;
    padding: 30px 20px 80px 20px;
    margin-right: 10px;
    background: url('../../assets/img/422c6817d7.png') 0 0 no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 768px) {
      margin: 0;
      padding: 0 15px;
      margin-top: 3px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item_top {
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        height: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .icon {
        width: 15%;
        margin-right: 10PX;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          @media screen and (max-width: 768px) {
            margin-bottom: 10px;
            margin-left: 6px;
          }
        }
      }
      .tit {
        width: 100%;
        color: #1c1c1c;
        font-size: 22px;
        font-weight: bold;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
    .item_desc {
      font-size: 16px;
      line-height: 20PX;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      padding-left: 34px;
      color: #646464;
      li{
          &:first-child{
            margin-top: 30px;
        }
      }
    }
  }
}
</style>