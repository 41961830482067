<template>
  <el-row class="core_three_wrap">
    <el-col class="three_left">
      <div class="left_tit">
        IDC产业动态知识库，支撑数据中心数智升级
      </div>
<div class="left_desc">
    <div class="desc_item">
        <div class="item_left">
            <img src="@/assets/xiazai.png" alt="">
        </div>
        <div class="item_right">
            <p>IDC动态知识库</p>
            <p>以亿级的数据中心和服务商为核心，构建的自学习的IDC动态知识库，涵盖4000+数据中心信息</p>
        </div>
    </div>
    <div class="desc_item">
        <div class="item_left">
            <img src="@/assets/img/xiazai2.png" alt="">
        </div>
        <div class="item_right">
            <p>数据中心监控平台</p>
            <p>集成设备监控、资源管理，实现数据中心的高效运维</p>
        </div>
    </div>
    <div class="desc_item">
        <div class="item_left">
            <img src="@/assets/img/xiazai3.png" alt="">
        </div>
        <div class="item_right">
            <p>数据分析与优化中心</p>
            <p>通过实时数据分析和趋势预测，推动数据中心资源的最优化配置</p>
        </div>
    </div>
    <div class="desc_item">
        <div class="item_left">
            <img src="@/assets/img/xiazai4.png" alt="">
        </div>
        <div class="item_right">
            <p>创新数据应用场景</p>
            <p>探索基于大数据和云计算的应用场景，实现绿色智能数据中心</p>
        </div>
    </div>
</div>
    </el-col>
    <el-col class="three_right">
      <img src="@/assets/img/right3 (1).png" alt="" />
    </el-col>
  </el-row>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.core_three_wrap {
  display: flex;
  justify-content: center;
  text-align: left;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .three_left {
    width: 40%;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    .left_tit{
      color: #3e3e3e;
      font-size: 20px;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        margin-top: 20PX;
      }
    }
    .left_desc{
      .desc_item{
        margin-top: 40px;
        display: flex;
        align-items: center;
        padding-left: 50px;
        .item_left{
          img{
            width: 50px;
            height: 50px;
          }
        }
        .item_right{
          margin-left: 20px;
          p{
            margin: 0;
            &:nth-child(1){
              color: #717171;
              font-size: 16px;
              font-weight: bold;
              @media screen and (max-width: 768px) {
                font-size: 1rem;
                margin-top: 20PX;
              }
            }
            &:nth-child(2){
              color: #717171;
              font-size: 14px;
              padding: 6px 0;
              @media screen and (max-width: 768px) {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
  .three_right {
    width: 20%;
    height: 100%;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 20PX;
    }
    img {
      width: 80%;
      margin-top: 40px;
    }
  }
}
</style>